import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FormField } from './FormField';
import { toast } from 'react-toastify';
import axios from 'axios';

const topics = [
  {
    title: 'Sprzedaż',
    value: 'sprzedaż',
  },
  {
    title: 'Wynajem',
    value: 'wynajem',
  },
  {
    title: 'Kupno',
    value: 'kupno',
  },
  {
    title: 'inne (wpisz)',
    value: 'inne',
  },
];

// Walidacja przy użyciu Yup
const validationSchema = Yup.object({
  name: Yup.string().required('Imię i nazwisko jest wymagane'),
  email: Yup.string()
    .email('Niepoprawny adres email')
    .required('Adres email jest wymagany'),
  phone: Yup.string().required('Numer telefonu jest wymagany'),
  topic: Yup.string().required('Temat jest wymagany'),
  agreements: Yup.boolean().oneOf([true], 'Musisz zaakceptować zgody'),
});

export default function ContactForm(props: { isModal?: boolean }) {
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      topic: '',
      agreements: false,
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        await toast.promise(
          axios.post('https://api.sellwell-nieruchomosci.pl/contact/', values),
          {
            pending: 'Wysyłanie wiadomości...',
            success: 'Wysłano wiadomość',
            error: 'Wystąpił błąd przy wysyłaniu',
          },
        );
        resetForm();
      } catch (error) {
        console.error('Error sending form:', error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleTopicChange = (value: any) => {
    formik.setFieldValue('topic', value);
  };

  return (
    <form
      onSubmit={formik.handleSubmit}
      className={`flex flex-col gap-4 ${
        props.isModal
          ? 'p-2 lg:p-4'
          : 'lg:w-[60%] xl:w-[40%] p-8 border-[1px] border-solid border-[#C7C7C7] bg-[#F3F3F3] rounded-[51px]'
      }`}
    >
      <div className="text-[30px] text-black font-bold uppercase">
        formularz kontaktowy
      </div>

      <FormField
        placeholder="Podaj imię i nazwisko"
        value={formik.values.name}
        onChange={(val) => formik.setFieldValue('name', val)}
        error={
          formik.touched.name && formik.errors.name ? formik.errors.name : null
        }
      />

      <FormField
        placeholder="Podaj e-mail"
        value={formik.values.email}
        onChange={(val) => formik.setFieldValue('email', val)}
        error={
          formik.touched.email && formik.errors.email
            ? formik.errors.email
            : null
        }
      />

      <FormField
        placeholder="Podaj numer telefonu"
        value={formik.values.phone}
        onChange={(val) => formik.setFieldValue('phone', val)}
        error={
          formik.touched.phone && formik.errors.phone
            ? formik.errors.phone
            : null
        }
      />

      <div className="text-[16px] text-black">Podaj temat</div>
      <div className="flex flex-row gap-2 flex-wrap">
        {topics.map((item) => (
          <div
            key={item.value}
            className={`px-4 py-2 uppercase font-bold text-[16px] border border-solid  cursor-pointer transition-all duration-300 rounded-[50px] ${
              formik.values.topic === item.value
                ? 'bg-white text-black border-black'
                : 'bg-black text-white border-transparent hover:bg-white hover:text-black hover:border-black'
            }`}
            onClick={() => handleTopicChange(item.value)}
          >
            {item.title}
          </div>
        ))}
      </div>

      {formik.values.topic === 'inne' && (
        <FormField
          placeholder="Podaj temat"
          value={formik.values.topic}
          onChange={(val) => formik.setFieldValue('topic', val)}
          error={
            formik.touched.topic && formik.errors.topic
              ? formik.errors.topic
              : null
          }
        />
      )}

      <div className="flex flex-row gap-2">
        <input
          type="checkbox"
          name="agreements"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          checked={formik.values.agreements}
        />
        <div className="text-[10px] text-[#ABABAB]">
          Wyrażam zgodę na przetwarzanie moich danych osobowych zgodnie z ustawą
          o ochronie danych osobowych w związku z realizacją zgłoszenia. Podanie
          danych jest dobrowolne, ale niezbędne do przetworzenia zapytania.
          Zostałem(am) poinformowany(a), że przysługuje mi prawo dostępu do
          swoich danych, możliwości ich poprawiania, żądania zaprzestania ich
          przetwarzania. Administratorem danych osobowych jest SellWell
          Nieruchomości Sp. Z o.o. z siedzibą w Łodzi ul. Piotrkowska 60 lok. 1
          90-001
        </div>
      </div>

      {formik.touched.agreements && formik.errors.agreements && (
        <div className="text-red-500 text-[14px] font-extrabold">
          {formik.errors.agreements}
        </div>
      )}

      <button
        type="submit"
        disabled={formik.isSubmitting}
        className="uppercase text-[16px] text-white font-semibold flex flex-row justify-between items-center bg-[#32A771] outline-none border-none rounded-[63px] py-2 px-4 max-w-[170px] cursor-pointer"
      >
        <div>Wyślij</div>
        <svg
          width="39"
          height="39"
          viewBox="0 0 39 39"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="19.5" cy="19.5" r="19.5" fill="white" />
          <path
            d="M19.9216 16.1716C21.4837 17.7337 21.4837 20.2663 19.9216 21.8284L16.75 25C16.4739 25.2761 16.0261 25.2761 15.75 25C15.4739 24.7239 15.4739 24.2761 15.75 24L18 21.75C19.5188 20.2312 19.5188 17.7688 18 16.25L15.75 14C15.4739 13.7239 15.4739 13.2761 15.75 13C16.0261 12.7239 16.4739 12.7239 16.75 13L19.9216 16.1716Z"
            fill="#32A771"
          />
        </svg>
      </button>
    </form>
  );
}
